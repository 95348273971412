import { Image } from "react-bootstrap";
import "./picture.css";
import SocialFollow from "./social";
interface PicProps {
  file: any;
  alt: string;
  socials?: boolean;
}

export default function Picture(props: PicProps) {
  return (
    <div id="slide">
      <Image className="responsive" src={props.file} alt={props.alt} />
      {props.socials ? <SocialFollow /> : ""}
    </div>
  );
}
