interface LinkProps {
  href: string;
  children: string | React.ReactNode;
  newTab?: boolean;
}
export default function Lnk(props: LinkProps) {
  if (props.newTab) {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  } else {
    return <a href={props.href}>{props.children}</a>;
  }
}
