import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../../components/navbar";
import TextField from "../../components/text";
import "./contact.css";

export default function Contact() {
  const clickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = "mailto:jan@jankretschmann.com";
  };
  return (
    <div className="wrapper">
      <NavBar active="contact" />
      <div className="child-wrapper">
        <TextField>
          <h2>Contact</h2>
          <p className="p1">
            Feel free to reach out if you're interested in collaborating, or if
            you would like me to present my research! I can be reached via
            email:
          </p>
        </TextField>
        <div className="p2 anim">
          <Link to="/contact" onClick={clickHandler}>
            jan@jankretschmann.com
          </Link>
        </div>
      </div>
    </div>
  );
}
