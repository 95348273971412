import "./list.css";
import "../custom.scss";
import { ListGroup } from "react-bootstrap";

interface ListType {
  str(): string;
  key(): number;
  // lnk(): string;
  childStr(): React.ReactNode;
}
interface ListProps {
  items: ListType[];
  sorted?: boolean;
}
export default function List(props: ListProps) {
  if (props.sorted) props.items.sort((a, b) => a.key() - b.key());
  const listItems = props.items.map((d: ListType) => (
    <ListGroup.Item
      style={{
        background: "var(--gradient-background)",
        color: "var(--font-color)",
      }}
      key={`${d.str()}${d.childStr()}`}
    >
      <div className="fw-bold">{d.str()}</div>
      <div className="row justify-content-md-left">{d.childStr()}</div>
    </ListGroup.Item>
  ));
  return (
    <ListGroup style={{ marginBottom: "20px" }} variant="flush">
      {listItems}
    </ListGroup>
  );
}
