import NavBar from "../../components/navbar";
import Picture from "../../components/picture";
import TextField from "../../components/text";
import portrait from "../../images/portrait.jpg";
import "./front.css";
import Lnk from "../../components/lnk";

export default function Front() {
  return (
    <div>
      <NavBar active="home" />
      <div className="pic">
        <Picture socials file={portrait} alt={"Portrait"} />
      </div>
      <div className="texts">
        <TextField>
          <h2 className="p0">About me</h2>
          <p className="p1">
            In December 2023, I have graduated from the{" "}
            <Lnk newTab href="https://www.uwm.edu">
              University of Wisconsin-Milwaukee
            </Lnk>{" "}
            (UWM) with a PhD in Mathematics. I'm interested in algebraic
            combinatorics and algebraic geometry, as well as applied math and
            computer science. My doctoral advisors were{" "}
            <Lnk newTab href="https://uwm.edu/math/people/willenbring-jeb/">
              Jeb Willenbring
            </Lnk>{" "}
            and{" "}
            <Lnk newTab href="https://www.pamelaeharris.com/">
              Pamela Harris
            </Lnk>
            . I have been working as a teaching assistant since 2019, and in
            2022 I have completed a software engineering internship at{" "}
            <Lnk newTab href="https://about.meta.com/">
              Meta
            </Lnk>{" "}
            in NYC.
          </p>
          <p className="p2">
            I received my Bachelor's Degree in{" "}
            <Lnk
              newTab
              href="https://www.fh-aachen.de/en/course-of-study/angewandte-mathematik-und-informatik-bsc"
            >
              Applied Mathematics and Computer Science
            </Lnk>{" "}
            (form. Scientific Programming) from{" "}
            <Lnk newTab href="https://www.fh-aachen.de/en/">
              FH Aachen
            </Lnk>{" "}
            in 2018. I participated in a Dual Studies program, which allowed me
            to complete a{" "}
            <Lnk
              newTab
              href="https://www.itc.rwth-aachen.de/cms/it-center/Lehre-Ausbildung/MATSE-Ausbildung/~letj/Ueber-MATSE/?lidx=1"
            >
              Mathematical-technical Software Developer
            </Lnk>{" "}
            apprenticeship, while simultaneously obtaining the bachelor's
            degree.
          </p>
          <p className="p3">
            For my master's degree, I continued studying{" "}
            <Lnk
              newTab
              href="https://www.fh-aachen.de/en/course-of-study/angewandte-mathematik-und-informatik-msc"
            >
              Applied Mathematics and Computer Science
            </Lnk>
            , focusing on mathematics. This allowed me to participate in the{" "}
            <Lnk
              newTab
              href="https://www.fh-aachen.de/en/course-of-study/angewandte-mathematik-und-informatik-msc/graduate-dual-degree"
            >
              Dual Degree Program
            </Lnk>{" "}
            program, for which I transferred to UWM for the second year of my
            studies, finishing with MS Degrees from both universities.
          </p>
          <p className="p4">
            Feel free to take a look around on my website, and reach out if you
            want to collaborate or have any questions. On here, you can find a
            list of courses that I have taught before, a summary of my current
            research and links to some of the non-research projects that I am or
            have been working on.
          </p>
          <p className="p5">
            You can find my CV{" "}
            <Lnk newTab href="/files/cv/cv.pdf">
              here
            </Lnk>
            .
          </p>
        </TextField>
      </div>
    </div>
  );
}
