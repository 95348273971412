import { Routes, Route } from "react-router-dom";
import Research from "./pages/research/research";
import Teaching from "./pages/teaching/teaching";
import "./App.css";
import Contact from "./pages/contact/contact";
import Misc from "./pages/misc/misc";
import "./custom.scss";
import Front from "./pages/front/front";
import GraphNav from "./components/graphnav/graphnav";
import Projects from "./pages/projects/projects";

function App() {
  return (
    <div className="App container-fluid">
      <Routes>
        <Route path="/" element={<Front />}></Route>
        <Route path="/teaching" element={<Teaching />}></Route>
        <Route path="/research" element={<Research />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/projects" element={<Projects />}></Route>
        <Route path="/misc" element={<Misc />}></Route>
        <Route path="*" element={<GraphNav />}></Route>
      </Routes>
    </div>
  );
}

export default App;
