import Lnk from "../components/lnk";

class Author {
  first: string;
  last: string;
  middle: string;

  constructor(first: string, last: string, middle?: string) {
    this.first = first;
    this.last = last;
    this.middle = middle ? `${middle} ` : " ";
  }

  str() {
    return `${this.first} ${this.middle}${this.last}`;
  }
}

class Paper {
  title: string;
  authors: Author[];
  reference: [string, string];
  year: number;
  journal?: string;

  constructor(
    title: string,
    authors: Author[],
    year: number,
    reference: [string, string],
    journal: string = ""
  ) {
    this.title = title;
    this.authors = authors;
    this.reference = reference;
    this.year = year;
    this.journal = journal;
  }

  str() {
    return this.title;
  }

  key() {
    return Math.random();
  }

  childStr() {
    return (
      <>
        <div className="col col-md-auto">
          <Lnk newTab href={this.reference[0]}>
            {this.reference[1]}{" "}
          </Lnk>
        </div>
        <div className="col col-md-auto">
          {`${this.authors.map((a) => a.str()).join(", ")} (${this.year})`}
        </div>
        <div>{this.journal}</div>
      </>
    );
  }
}

const me: Author = new Author("Jan", "Kretschmann");
const will: Author = new Author("William", "Erickson", "Q.");
const pamela: Author = new Author("Pamela", "Harris", "E.");
const carlos = new Author("J. Carlos", "Martínez Mori");

export const PAPERS: Paper[] = [
  new Paper(
    "Earth Mover's Distance Between Grade Distribution Data with Fixed Mean",
    [me],
    2020,
    [
      "https://www.proquest.com/docview/2453828099",
      "MS Thesis: view on Proquest",
    ]
  ),
  new Paper(
    "Counting Parking Sequences and Parking Assortments Through Permutations",
    [
      new Author("Spencer", "Franks", "J."),
      pamela,
      new Author("Kim", "Harry"),
      me,
      new Author("Megan", "Vance"),
    ],
    2022,
    [
      "https://ecajournal.haifa.ac.il/Volume2024/ECA2024_S2A2.pdf",
      "Enumerative Combinatorics and Applications 4:1 (2024)",
    ]
  ),
  new Paper(
    "The sum of all width-one matrices",
    [will, me],
    2023,
    ["https://arxiv.org/abs/2303.08924", "arXiv:2303.08924"],
    "European Journal of Combinatorics 115 (2024): 103799"
  ),
  new Paper("The sum of width-one tensors", [will, me], 2023, [
    "https://ecajournal.haifa.ac.il/Volume2024/ECA2024_S2A7.pdf",
    "Enumerative Combinatorics and Applications 4:1 (2024)",
  ]),
  new Paper(
    "Lucky Cars and the Quicksort Algorithm",
    [pamela, me, carlos],
    2023,
    ["https://arxiv.org/abs/2306.13065", "arXiv:2306.13065"],
    "The American Mathematical Monthly, 1-7"
  ),
  new Paper(
    "Boolean intervals in the weak order of S_n",
    [new Author("Jennifer", "Elder"), pamela, me, carlos],
    2023,
    ["https://arxiv.org/abs/2306.14734", "arXiv:2306.14734"]
  ),
  new Paper(
    "Combinatorial problems related to optimal transport and parking functions",
    [me],
    2023,
    [
      "https://dc.uwm.edu/cgi/viewcontent.cgi?article=4418&context=etd",
      "Dissertation: view on ProQuest",
    ]
  ),
].reverse();
