import NavBar from "../../components/navbar";
import TextField from "../../components/text";
import { RSKLINK } from "../../resources/constants";
import "./research.css";
import "../../components/global.css";
import Lnk from "../../components/lnk";
import { PAPERS } from "../../resources/researchlist";
import List from "../../components/list";
import pictureFB from "../../images/fb.png";
import Picture from "../../components/picture";

export default function Research() {
  // const clickHandler = (e: React.MouseEvent) => {
  //   e.preventDefault();
  //   window.open(RSKLINK, "__blank")?.focus();
  // };
  return (
    <div className="wrapper">
      <NavBar active="research" />
      <div className="child-wrapper">
        <TextField>
          <h2>Research</h2>
          <p className="p1">
            My research focuses mainly on algebraic combinatorics, where I have
            been working on applications of the{" "}
            <Lnk
              newTab
              href="https://en.wikipedia.org/wiki/Earth_mover%27s_distance"
            >
              Earth Mover's Distance
            </Lnk>{" "}
            on ordered pairs of compositions, its relation to{" "}
            <Lnk newTab href="https://en.wikipedia.org/wiki/Young_tableau">
              Young Tableaux
            </Lnk>{" "}
            through the{" "}
            <Lnk
              newTab
              href="https://en.wikipedia.org/wiki/Robinson%E2%80%93Schensted%E2%80%93Knuth_correspondence"
            >
              Robinson-Schenstedt-Knuth Correspondence
            </Lnk>{" "}
            and its application to representation theory. Additionally, I'm
            interested in algebraic geometry and groebner basis theory. Here is
            a list of my work:
          </p>
          <div className="p2 anim">
            <List items={PAPERS} />
          </div>
          <p className="p3">
            To get a better understanding of some of my research, check out my{" "}
            <Lnk newTab href={RSKLINK}>
              implementation
            </Lnk>{" "}
            of the RSK-Correspondence applied to contingency tables.
          </p>
          <p className="p4">
            In my{" "}
            <Lnk newTab href="https://dc.uwm.edu/etd/2542/">
              Master's Thesis
            </Lnk>
            , I found a generating function to compute the expected Earth
            Mover's Distance of ordered pairs of compositions, with a fixed
            weighted average. In an applied context, this corresponds to finding
            the average Earth Mover's Distance between the grade distributions
            of classes with a fixed number of students, where each class has the
            same GPA.
          </p>
          <h2>Conferences</h2>
          <p>
            A handful of times, I have been invited to give talks or poster
            presentations about my research. Recently, I have given{" "}
            <Lnk newTab href="/files/talks/monge.pdf">
              this
            </Lnk>{" "}
            talk about the Monge property at a special session of the{" "}
            <Lnk
              newTab
              href="https://www.ams.org/meetings/sectional/2318_program.html"
            >
              AMS eastern sectional meeting
            </Lnk>
            .
          </p>
          <p>
            Additionally, I have given a{" "}
            <Lnk newTab href="/files/talks/poster.pdf">
              poster
            </Lnk>{" "}
            presentation about our work on parking assortments at{" "}
            <Lnk newTab href="https://2023.permutationpatterns.com/">
              Permutation Patterns 2023
            </Lnk>{" "}
            in Dijon, France.
          </p>
          <h2>Applied Work</h2>
          <p className="p6">
            Before starting to research in pure mathematics, my work was
            centered around applied mathematics and computer science. During my
            undergraduate degree, I completed an apprenticeship at{" "}
            <Lnk newTab href="https://www.dsa.de/en/">
              DSA
            </Lnk>{" "}
            in Aachen, Germany, where I also worked part-time during the first
            year of my master's degree. Here, my main contribution was the
            design and (prototypical) implementation of a{" "}
            <Lnk newTab href="https://en.wikipedia.org/wiki/Single_sign-on">
              Single sign-on
            </Lnk>{" "}
            solution, used across several{" "}
            <Lnk newTab href="https://angularjs.org/">
              AngularJS
            </Lnk>{" "}
            web applications and Java{" "}
            <Lnk newTab href="https://spring.io/">
              Spring
            </Lnk>{" "}
            Rest APIs. Aside from my current research, I still spend
            considerable time on computer science and programming.
          </p>
          <p className="p7">
            I have completed a summer internship in 2022 at{" "}
            <Lnk newTab href="https://about.meta.com/">
              Meta
            </Lnk>{" "}
            in New York. Here, I spent the summer implementing a pilot version
            for a{" "}
            <Lnk
              newTab
              href="https://www.geeksforgeeks.org/traffic-aware-routing/"
            >
              Load-Aware Routing
            </Lnk>{" "}
            (LAR) Library for inter datacenter traffic. The project was
            completed successfully, and we were able to deploy a cloud service
            tasked with visualizing the projected value of the key metric used
            to compare the efficiency of routing solutions. The service
            visualized the obeserved metric for both the LAR solution and the
            current approach in a time series, which showed an improvement using
            the LAR approach.
          </p>
          <div className="p8 anim imgcontainer">
            <Picture file={pictureFB} alt="me at the Meta office" />
          </div>
        </TextField>
      </div>
    </div>
  );
}
