import NavBar from "../../components/navbar";
import TextField from "../../components/text";
import { LOREM } from "../../resources/texts";
import "./misc.css";

export default function Misc() {
  return (
    <div>
      <NavBar active="misc" />
      <div className="Misc">
        <TextField title={"Misc"}>{LOREM}</TextField>
      </div>
    </div>
  );
}
