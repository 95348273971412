import Lnk from "../components/lnk";

class Semester {
  semester: string;
  year: number;
  syllabus: string;
  constructor(s: string, y: number, sy: string) {
    this.semester = s;
    this.year = y;
    this.syllabus = sy;
  }

  str() {
    return `${this.semester} ${this.year}`;
  }

  output() {
    return (
      <div className="col col-md-auto">
        <Lnk newTab href={this.syllabus}>
          {this.str()}
        </Lnk>
      </div>
    );
  }
}

class MathClass {
  num: number;
  title: string;
  cat: string;
  semesters: Semester[];
  constructor(c: string, n: number, t: string, s: Semester[]) {
    this.num = n;
    this.title = t;
    this.cat = c;
    this.semesters = s;
  }

  str() {
    return `${this.cat} ${this.num}: ${this.title}`;
  }

  key() {
    return this.num;
  }

  sem() {
    return (
      // <div style={{ display: "flex", flexDirection: "row" }}>
      // <div className="col col-md-auto">
      <>
        {this.semesters.map((s) => (
          <div className="col col-md-auto" key={`${this.str()}${s.str()}`}>
            {s.output()}
          </div>
        ))}
      </>
    );
  }

  childStr() {
    return (
      <>
        <div className="col col-lg-1"> Syllabus:</div>
        {this.sem()}
      </>
    );
  }
}
export const CLASSES = [
  new MathClass("Math", 94, "Foundations of Elementary Mathematics", [
    new Semester("Fall", 2019, "/files/syllabi/94f19.pdf"),
  ]),
  new MathClass("Math", 98, "Algebraic Literacy I", [
    new Semester("Fall", 2021, "/files/syllabi/98f21.pdf"),
  ]),
  new MathClass("Math", 108, "Algebraic Literacy II", [
    new Semester("Fall", 2021, "/files/syllabi/108f21.pdf"),
  ]),
  new MathClass("Math", 105, "Introduction to College Algebra", [
    new Semester("Spring", 2020, "/files/syllabi/105s20.pdf"),
    new Semester("Summer", 2021, "/files/syllabi/105su21.pdf"),
  ]),
  new MathClass("Math", 115, "Precalculus", [
    new Semester("Fall", 2020, "/files/syllabi/115f20.pdf"),
    new Semester("Spring", 2021, "/files/syllabi/115s21.pdf"),
    new Semester("Spring", 2022, "/files/syllabi/115s22.pdf"),
  ]),
  new MathClass("Math", 208, "Quantitative Models for Business", [
    new Semester("Spring", 2023, "/files/syllabi/208s23.pdf"),
  ]),
  new MathClass("Math", 211, "Survey in Calculus and Analytic Geometry", [
    new Semester("Fall", 2022, "/files/syllabi/211f22.pdf"),
  ]),
];
