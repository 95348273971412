import Graph from "react-vis-network-graph";
import { useNavigate } from "react-router-dom";
import portrait from "../../images/portrait-small.jpeg";
import teaching from "../../images/teaching-large.jpeg";
import codepic from "../../images/code-large.png";
import researchpic from "../../images/research-large.png";

const arrowConfig = {
  to: {
    enabled: false,
    type: "circle",
  },
};

const smoothConfig = {
  enabled: true,
};

const scalingConfig = {
  min: 1,
  max: 60,
};
const edgeWidth = 10;
const edgeLength = 1;
const edgeColorConfig = { color: "#939cab", opacity: 0.2 };

const nodeOpacity = 0;

const nodeFontConfig = {
  color: "black",
};

const edgeShadowConfig = {
  enabled: true,
};
const nodeBorderWidth = 12;

export default function GraphNav() {
  const navigate = useNavigate();

  // handle navigation on click
  const clickHandler = (event: any) => {
    if (!event.nodes || event.nodes.length === 0) {
      return;
    }

    let node = event.nodes[0];
    if (node === "emd" || node === "pf") {
      node = "research";
    } else if (node === "rsk" || node === "audiosearch") {
      node = "projects";
    } else if (node === "me") {
      node = "";
    }
    navigate(`/${node}`);
  };

  // build graph to display
  let nodes = [
    {
      id: "me",
      label: "about me",
      shape: "circularImage",
      image: portrait,
      shapeProperties: { useImageProperties: true },
      value: 10,
      scaling: scalingConfig,
      font: nodeFontConfig,
      borderWidth: 0,
    },
    {
      id: "research",
      label: "research",
      value: 10,
      shape: "circularImage",
      image: researchpic,
      font: nodeFontConfig,
      borderWidth: 0,
    },
    {
      id: "teaching",
      label: "teaching",
      value: 10,
      shape: "circularImage",
      image: teaching,
      borderWidth: 0,
    },
    {
      id: "contact",
      label: "contact",
      value: 1,
      opacity: nodeOpacity,
    },
    {
      id: "projects",
      label: "projects",
      value: 10,
      shape: "circularImage",
      image: codepic,
      font: nodeFontConfig,
      borderWidth: 0,
    },
    {
      id: "audiosearch",
      label: "audiosearch",
      value: 0.1,
      font: nodeFontConfig,
      borderWidth: nodeBorderWidth,
      opacity: nodeOpacity,
    },
    {
      id: "rsk",
      label: "robinson-schenstedt-knuth",
      value: 0.1,
      font: nodeFontConfig,
      borderWidth: nodeBorderWidth,
      opacity: nodeOpacity,
    },
    {
      id: "emd",
      label: "earth mover's distance",
      value: 0.1,
      font: nodeFontConfig,
      borderWidth: nodeBorderWidth,
      opacity: nodeOpacity,
    },
    {
      id: "pf",
      label: "parking functions",
      value: 0.1,
      font: nodeFontConfig,
      borderWidth: nodeBorderWidth,
      opacity: nodeOpacity,
    },
  ];

  let edges = [
    {
      from: "me",
      to: "research",
      arrows: arrowConfig,
      smooth: smoothConfig,
      width: edgeWidth,
      color: edgeColorConfig,
      shadow: edgeShadowConfig,
      length: edgeLength,
    },
    {
      from: "me",
      to: "teaching",
      arrows: arrowConfig,
      smooth: smoothConfig,
      width: edgeWidth,
      color: edgeColorConfig,
      shadow: edgeShadowConfig,
      length: edgeLength,
    },
    {
      from: "me",
      to: "contact",
      arrows: arrowConfig,
      smooth: smoothConfig,
      width: edgeWidth,
      color: edgeColorConfig,
      shadow: edgeShadowConfig,
      length: edgeLength,
    },
    {
      from: "me",
      to: "projects",
      arrows: arrowConfig,
      smooth: smoothConfig,
      width: edgeWidth,
      color: edgeColorConfig,
      length: edgeLength,
      shadow: edgeShadowConfig,
    },
    {
      from: "projects",
      to: "audiosearch",
      arrows: arrowConfig,
      smooth: smoothConfig,
      length: edgeLength,
      width: 0.5 * edgeWidth,
      color: edgeColorConfig,
      shadow: edgeShadowConfig,
    },
    {
      from: "projects",
      to: "rsk",
      arrows: arrowConfig,
      smooth: smoothConfig,
      length: edgeLength,
      width: 0.5 * edgeWidth,
      color: edgeColorConfig,
      shadow: edgeShadowConfig,
    },
    {
      from: "research",
      to: "emd",
      arrows: arrowConfig,
      smooth: smoothConfig,
      length: edgeLength,
      width: 0.5 * edgeWidth,
      color: edgeColorConfig,
      shadow: edgeShadowConfig,
    },
    {
      from: "research",
      to: "pf",
      arrows: arrowConfig,
      smooth: smoothConfig,
      length: edgeLength,
      width: 0.5 * edgeWidth,
      color: edgeColorConfig,
      shadow: edgeShadowConfig,
    },
  ];
  const graph = {
    nodes: nodes,
    edges: edges,
  };
  const options = {
    height: `${window.innerHeight}px`,
    interaction: { hover: true },
  };
  return (
    <Graph
      graph={graph}
      events={{ click: clickHandler }}
      options={options}
      getNetwork={(network: any) => {
        network.moveTo({ scale: 1 });
        network.stopSimulation();

        var bigBB = {
          top: Infinity,
          left: Infinity,
          right: -Infinity,
          bottom: -Infinity,
        };
        nodes
          .map((n) => n.id)
          .forEach(function (i: any) {
            var bb = network.getBoundingBox(i);
            if (bb.top < bigBB.top) bigBB.top = bb.top;
            if (bb.left < bigBB.left) bigBB.left = bb.left;
            if (bb.right > bigBB.right) bigBB.right = bb.right;
            if (bb.bottom > bigBB.bottom) bigBB.bottom = bb.bottom;
          });

        var canvasWidth = network.canvas.body.container.clientWidth;
        var canvasHeight = network.canvas.body.container.clientHeight;

        var scaleX = canvasWidth / (bigBB.right - bigBB.left);
        var scaleY = canvasHeight / (bigBB.bottom - bigBB.top);
        var scale = scaleX;
        if (scale * (bigBB.bottom - bigBB.top) > canvasHeight) scale = scaleY;

        if (scale > 1) scale = 0.9 * scale;

        network.moveTo({
          scale: scale,
          position: {
            x: (bigBB.right + bigBB.left) / 2,
            y: (bigBB.bottom + bigBB.top) / 2,
          },
          animation: {
            // animation object, can also be Boolean
            duration: 2000, // animation duration in milliseconds (Number)
            easingFunction: "easeInOutQuad", // Animation easing function, available are:
          },
        });
      }}
    ></Graph>
  );
}
