import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "./social.css";

export default function SocialFollow() {
  return (
    <div className="social-container">
      <a
        href="https://www.linkedin.com/in/jan-kretschmann-4768b7184/"
        className="linkedin social"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} size="1x" />
      </a>
      <a
        href="https://www.github.com/githubacc12"
        className="github social"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} size="1x" />
      </a>
    </div>
  );
}
