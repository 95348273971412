import { Navbar, Container, Nav, Offcanvas } from "react-bootstrap";
// import { Link } from "react-router-dom";

interface NavBarProps {
  active: string;
}

export default function NavBar(props: NavBarProps) {
  // const homeActive = props.active === "home";
  const teachingActive = props.active === "teaching";
  const researchActive = props.active === "research";
  const contactActive = props.active === "contact";
  const projectsActive = props.active === "projects";
  // const miscActive = props.active === "misc";
  return (
    <Navbar
      fixed="top"
      expand="lg"
      style={{ background: "white", opacity: 0.9 }}
    >
      <Container>
        <Navbar.Brand href="/" style={{ color: "var(--font-color)" }}>
          Jan Kretschmann
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Offcanvas
          className="color-nav"
          id="navbar"
          aria-labelledby="navbar-label"
        >
          <Offcanvas.Header
            closeButton
            style={{
              background: "var(--gradient-background)",
              color: "var(--font-color)",
            }}
          >
            <Offcanvas.Title id="navbar-label">Navigate</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            style={{
              background: "var(--gradient-background)",
            }}
          >
            <Nav className="me-auto">
              <Nav.Link
                href="/research"
                style={{ color: "var(--font-color)" }}
                active={researchActive}
              >
                Research
              </Nav.Link>
              <Nav.Link
                href="/teaching"
                style={{ color: "var(--font-color)" }}
                active={teachingActive}
              >
                Teaching
              </Nav.Link>
              <Nav.Link
                href="/projects"
                style={{ color: "var(--font-color)" }}
                active={projectsActive}
              >
                Projects
              </Nav.Link>
              <Nav.Link
                href="/contact"
                style={{ color: "var(--font-color)" }}
                active={contactActive}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
