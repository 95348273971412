import List from "../../components/list";
import NavBar from "../../components/navbar";
import TextField from "../../components/text";
import { CLASSES } from "../../resources/lists";
import "./teaching.css";
import "../../components/global.css";
import Lnk from "../../components/lnk";

export default function Teaching() {
  return (
    <div className="wrapper">
      <NavBar active="teaching" />

      <div className="child-wrapper">
        <TextField>
          <h2>Teaching</h2>
          <p className="p1">
            I have been a graduate teaching assistant at UWM since August 2019.
            I have taught classes on the undergraduate level for the department
            of mathematics: for three semesters, I have taught Precalculus,
            which consists for the most part of trigonometry. I have taught
            different introductory algebra classes dealing with different types
            of functions, and I have taught a discussion of business calculus.
          </p>
          <p className="p2">
            Take a look the{" "}
            <Lnk newTab href="https://catalog.uwm.edu/courses/math/">
              UWM Course Catalog
            </Lnk>{" "}
            for more information on the classes I taught, listed below.
          </p>
          <div className="p3 anim">
            <List sorted items={CLASSES} />
          </div>
        </TextField>
      </div>
    </div>
  );
}
