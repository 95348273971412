import Lnk from "../../components/lnk";
import NavBar from "../../components/navbar";
import TextField from "../../components/text";
import "./projects.css";

export default function Projects() {
  return (
    <div className="wrapper">
      <NavBar active="projects" />
      <div className="child-wrapper">
        <TextField>
          <h2>Personal Projects</h2>
          <p className="p1">
            In my free time, I like to work on side projects, which I usually
            use to solve a problem I encounter.
          </p>
          <p className="p2">
            On this page, I'll highlight 2 projects I've worked on, but feel
            free to browse my{" "}
            <Lnk newTab href="https://github.com/GitHubAcc12">
              GitHub
            </Lnk>
            -profile if you would like to see more.
          </p>
          <div className="row" style={{ paddingTop: "30px" }}>
            <div className="col">
              <h3 className="p3 anim">audiosearch</h3>
              <p className="p4">
                When the COVID-19 pandemic hit the schools in the United States,
                the day-to-day of teachers and students were massively impacted
                (just as the day-to-day of many, if not all, others).
              </p>
              <p className="p5">
                Teaching in person was no longer an option, and everyone had to
                adapt to learning and teaching online.
              </p>
              <p className="p6">
                Platforms such as{" "}
                <Lnk newTab href="https://zoom.us/">
                  Zoom
                </Lnk>
                ,{" "}
                <Lnk
                  newTab
                  href="https://www.microsoft.com/en-us/microsoft-teams/log-in"
                >
                  Microsoft Teams
                </Lnk>{" "}
                and{" "}
                <Lnk
                  newTab
                  href="https://help.blackboard.com/de-de/Learn/Instructor/Ultra/Interact/Blackboard_Collaborate/Collaborate_Ultra"
                >
                  Collaborate Ultra
                </Lnk>{" "}
                became vital tools for anyone who valued attending their
                classes.
              </p>
              <p className="p7">
                This form of digital teaching brought one upside for students:
                nearly every lecture could now be recorded, and my lectures were
                readily available for students to rewatch online.
              </p>
              <p className="p8">
                The abundance in video material that was available made me ask
                myself: Is this even usable? If you want to rewatch a brief
                section, an explanation of just one topic, will you really try
                to find that in a 3h lecture video?
              </p>
              <p className="p10">
                The idea came to me when I was searching a large pdf-file for a
                keyword: I want to be able to do the same thing with video
                files. Find a keyword. So, I started the development of my
                program <i>audiosearch</i>.
              </p>
              <p className="p11">
                <i>audiosearch</i>, which can be found on{" "}
                <Lnk
                  newTab
                  href="https://github.com/GitHubAcc12/audiosearch-backend"
                >
                  GitHub
                </Lnk>
                , is a REST-API that takes the URL to a lecture video (or any
                video, for that matter), and a keyword to find. The audio gets
                separated from the video using{" "}
                <Lnk newTab href="https://ffmpeg.org/">
                  ffmpeg
                </Lnk>
                , and split into several smaller chunks.
              </p>
              <p className="p12">
                Those chunks are then sent to the{" "}
                <Lnk newTab href="https://cloud.google.com/apis?hl=de">
                  Google Cloud API
                </Lnk>
                , which transcribes the video material and adds timestamps to
                every recognized word. I am thus able to compute the exact
                timestamps of occurrences of the provided keyword from the
                timestamps found by the Google Cloud API and the number of
                chunks the audio was split into.
              </p>
            </div>
            <div className="col">
              <h3 className="p3 anim">robinson-schenstedt-knuth visualizer</h3>
              <p className="p4">
                The{" "}
                <Lnk
                  newTab
                  href="https://en.wikipedia.org/wiki/Robinson%E2%80%93Schensted%E2%80%93Knuth_correspondence"
                >
                  Robinson-Schenstedt-Knuth Correspondence
                </Lnk>{" "}
                (short: RSK) gives a bijection between an array with 2 weakly
                increasing rows, and a pair of semi-standard{" "}
                <Lnk
                  newTab
                  href="https://en.wikipedia.org/wiki/Young_tableau#Tableaux"
                >
                  Young Tableaux
                </Lnk>
                .
              </p>
              <p className="p5">
                The RSK describes an algorithm using only the <i>bumping</i> and{" "}
                <i>sliding</i> operations on Young Tableaux.
              </p>
              <p className="p6">
                I encountered an application of RSK in my own research already:
                in my own{" "}
                <Lnk newTab href="https://dc.uwm.edu/etd/2542/">
                  master's thesis
                </Lnk>
                , I computed the average earth mover's distance between grade
                distributions with fixed mean.
              </p>
              <p className="p7">
                To compute the earth mover's distance is to solve an optimal
                transport problem: given 2 grade distributions, we want to find
                the minimum number of operations necessary to transform one
                distribution into the other.
              </p>
              <p className="p8">
                For example, imagine 2 classes with one student each. In class 1
                the student has an A, in class 2 the student has an F, resulting
                in the two distributions:
              </p>
              <div className="t-wrapper anim p9">
                <table>
                  <tbody>
                    <tr>
                      <th>Class 1</th>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th>Class 2</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="p10">
                We see, that to transform the first row into the second, we need
                to move the entry in the left column all the way over to the
                right column. This movement can be encoded, in the 5 by 5 matrix
                that has exactly one positive entry at position (1,5) and is
                zero elsewhere.
              </p>
              <p className="p11">
                The earth mover's distance can now be computed from such a
                matrix, a matrix that encodes the <i>optimal</i> way to
                transfrom distribution 1 to distribution 2.
              </p>
              <p className="p12">
                To find such a matrix for any arbitrary ordered pair of grade
                distributions, we just apply RSK: we interpret the distributions
                as 1-row Young Tableaux, and we write the <i>words</i> of the
                tableaux above each other. Then, reading the two lines
                column-wise, we will see exactly the indices of the optimal
                transport matrix which we have to increment:
              </p>
              <div className="t-wrapper anim p13">
                <table>
                  <tbody>
                    <tr>
                      <th>Word 1</th>
                      <td>1</td>
                    </tr>
                    <tr>
                      <th>Word 2</th>
                      <td>5</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="p14">
                Which we identify as the positive index in the optimal transport
                matrix, (1,5). Feel free to play around with my{" "}
                <Lnk
                  newTab
                  href="https://githubacc12.github.io/rsk-correspondence/"
                >
                  RSK visualizer
                </Lnk>{" "}
                to explore more applications of RSK.
              </p>
            </div>
          </div>
        </TextField>
      </div>
    </div>
  );
}
