import "./text.css";
import "../custom.scss";
interface TextProps {
  children: string | React.ReactNode[];
  title?: string;
}

export default function TextField(props: TextProps) {
  return <div className="mainText">{props.children}</div>;
}
